<template>
  <v-app>
    <v-img src="@/assets/images/main-background.png">
      <v-app-bar app clipped-left dark prominent height="150px">
        <v-img
          src="@/assets/images/site-banner.jpg"
          height="150px"
          width="100vw"
          style="position: absolute; top: 0; left: 0"
        >
          <v-app-bar-title>
            <div class="header-container">
              <div class="header">John Keeble</div>
              <div class="subheader" v-if="$vuetify.breakpoint.mdAndUp">
                Author, Journalist, Photographer
              </div>
              <div class="subheader" v-else>
                Author, Journalist,<br />
                Photographer
              </div>
            </div>
          </v-app-bar-title>

          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-tabs
              style="margin-top: 37px"
              background-color="rgba(0,0,0,0.25)"
              color="white"
            >
              <v-tab v-for="tab in navItems" :key="tab.id" :to="tab.path">
                {{ tab.name }}
              </v-tab>
            </v-tabs>
          </template>

          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
          </template>
        </v-img>
      </v-app-bar>

      <v-bottom-sheet v-model="drawer">
        <drawer-nav @close="drawer = false"></drawer-nav>
      </v-bottom-sheet>
      <!-- <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <drawer-nav></drawer-nav>
      </v-navigation-drawer> -->

      <v-main>
        <router-view />
      </v-main>
    </v-img>
    <v-footer color="rgba(0,0,0,0)" class="text-caption pr-12">
      <v-spacer></v-spacer>
      Site built by&nbsp;
      <a href="https://castoware.com" target="_blank">CastoWare Development</a>
    </v-footer>
    <v-snackbar :color="snack.color" v-model="snack.visible">
      {{ snack.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import navItems from "@/assets/nav-items";
import DrawerNav from "@/components/App/DrawerNav";
import { mapState } from "vuex";

export default {
  name: "App",

  metaInfo: {
    titleTemplate: "John Keeble - %s",
  },

  components: { DrawerNav },

  data: () => ({
    navItems,
    drawer: false,
    socials: require("@/assets/socials.json"),
  }),

  computed: {
    ...mapState(["snack"]),
  },
  mounted() {
    this.$store.dispatch("siteData", { dataName: "bookData", type: "json" });
  },
};
</script>

<style>
.header-container {
  width: 100vw;
  text-align: right;
  padding-right: 3rem;
}

.header,
.subheader {
  font-family: serif;
}

.header {
  font-size: 1.5rem;
}

.subheader {
  font-size: 1rem;
}
</style>
