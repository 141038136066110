<template>
  <div>
    <sign-in v-if="!tokenValid"></sign-in>
    <dashboard v-else></dashboard>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SignIn from "@/components/Admin/SignIn";
import Dashboard from "@/components/Admin/Dashboard";

export default {
  components: { SignIn, Dashboard },
  computed: {
    ...mapState(["adminToken", "tokenValid"]),
  },
  methods: {
    ...mapActions(["checkAdmin"]),
  },
  mounted() {
    this.checkAdmin(this.adminToken);
  },
};
</script>
