<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="8">
          <v-card>
            <v-card-title> Admin Login </v-card-title>
            <v-card-text>
              <v-text-field
                type="email"
                label="E-Mail"
                v-model="login.email"
              ></v-text-field>
              <v-text-field
                :type="showPass ? 'text' : 'password'"
                label="Password"
                v-model="login.password"
                :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPass = !showPass"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="adminSignIn(login)">Sign In</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const isDevelopment = process.env.NODE_ENV == "development";
export default {
  data: () => ({
    login: {
      email: isDevelopment ? "castoware@gmail.com" : "",
      password: isDevelopment ? "zHRyrdXuxIw5nO8kZzV1" : "",
    },
    showPass: false,
  }),
  methods: {
    ...mapActions(["adminSignIn"]),
  },
};
</script>
