<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-carousel v-model="carouselValue" hide-delimiters height="55vh">
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.image"
              contain
            >
              <template v-slot:placeholder>
                <div
                  class="d-flex justify-center align-center"
                  style="height: 100%;"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-2"
                    size="80"
                  />
                </div>
              </template>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col>
          <v-card flat style="background-color: rgba(0, 0, 0, 0);">
            <v-card-title>{{ viewing.title }}</v-card-title>
            <v-card-text v-html="caption"></v-card-text>
            <v-card-text>
              <h2>Retailer Links</h2>
              <div v-html="retailLinks"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <mailing-list-info-dialog :info="info"></mailing-list-info-dialog>
  </div>
</template>

<script>
  import MailingListInfoDialog from "@/components/MailingListInfoDialog";
  // import { markdown } from "markdown";
  import { mapState } from "vuex";
  const md = require("markdown-it")({ html: true });

  export default {
    components: { MailingListInfoDialog },
    data: () => ({
      carouselValue: 0,
      email: "",
      info: { visible: false },
    }),

    computed: {
      ...mapState(["siteData"]),
      caption() {
        // return markdown.toHTML(this.viewing.caption);
        return md.render(this.viewing.caption);
      },
      viewing() {
        const item = this.items[this.carouselValue];
        this.$emit("set-item", item);
        if (!item) return { link: "", caption: "" };
        return item;
      },
      items() {
        return this.siteData.bookData;
      },
      retailLinks() {
        if (!this.viewing.links) return [];
        const links = this.viewing.links.map(({ retailer, url }) => {
          return `<a href="${url}" target="_blank"yan>${retailer}</a>`;
        });
        return links.length > 0
          ? `<ul><li>${links.join("</li><li>")}</li></ul>`
          : `Not yet available for purchase`;
      },
    },
  };
</script>
