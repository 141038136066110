<template>
  <div>
    <v-tabs right>
      <v-tab to="/admin/home"> Home </v-tab>
      <v-tab to="/admin/about">About </v-tab>
      <v-tab to="/admin/features">Features</v-tab>
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
mapState, mapActions;

export default {
  //
};
</script>

<style>
.selected {
  background-color: #555555;
}
</style>
