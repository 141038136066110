import { decompress } from "@/assets/gz";

export default (response) => {
  if (response.status != "ok") {
    throw `Error: ${response.message}`;
  }

  if (response.compressed) response.data = decompress(response.data);

  return JSON.parse(response.data);
};
