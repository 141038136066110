import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "@/views/About";
import Contact from "@/views/Contact";
import Features from "@/views/Features";
import Admin from "@/views/Admin";
import FeatureDashboard from "@/components/Admin/FeatureDashboard";
import AboutDashboard from "@/components/Admin/AboutDashboard";
import HomeDashboard from "@/components/Admin/HomeDashboard";
import NotFound from "@/views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { show: true },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { show: true },
  },
  {
    path: "/features",
    name: "Features",
    component: Features,
    meta: { show: true },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: { show: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { show: false },
    children: [
      {
        path: "features",
        name: "Features Dashboard",
        component: FeatureDashboard,
        meta: { show: false },
      },
      {
        path: "about",
        name: "About Dashboard",
        component: AboutDashboard,
        meta: { show: false },
      },
      {
        path: "home",
        name: "Home Dashboard",
        component: HomeDashboard,
        meta: { show: false },
      },
    ],
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: { show: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
