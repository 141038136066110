<template>
  <div>
    <v-container>
      <slot></slot>
    </v-container>
  </div>
</template>

<script>
  export default {
    props: ["image"],
  };
</script>