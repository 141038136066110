<template>
  <v-dialog v-model="info.visible" max-width="65vw">
    <v-card>
      <v-card-title>
        Mailing List Info
        <v-spacer></v-spacer>
        <v-icon @click="info.visible = false"> mdi-close-circle-outline </v-icon>
      </v-card-title>
      <v-card-text v-html="disclaimer.text" class="mt-5"> </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["info"],
  data: () => ({
    disclaimer: require("@/assets/mailing-list-disclaimer.json"),
  }),
};
</script>
