<template>
  <v-dialog v-model="showDialog" max-width="65vh" overlay-opacity="100">
    <v-card>
      <v-card-title> New Section </v-card-title>
      <v-card-text>
        <v-text-field label="Section Name" v-model="sectionName"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showDialog = false">Cancel</v-btn>
        <v-btn text @click="addSection">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["visible"],
  data: () => ({ sectionName: "" }),
  computed: {
    showDialog: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update-visible", v);
      },
    },
  },
  methods: {
    ...mapActions(["createFeatureSection"]),
    addSection() {
      this.createFeatureSection(this.sectionName).then((response) => {
        if (response) {
          this.showDialog = false;
          this.sectionName = "";
        }
      });
    },
  },
};
</script>
