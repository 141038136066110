<template>
  <v-container>
    <v-card dark class="rounded-xl">
      <v-img src="@/assets/images/features-banner.jpg" height="100%"></v-img>

      <v-row dense>
        <v-col cols="12">
          <v-card-text>
            Thank you for stopping by. Please feel free to browse and enjoy
            whatever appeals to you. I shall be adding offers and more articles
            over time.
          </v-card-text>
        </v-col>
      </v-row>
      <featured-contents></featured-contents>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import FeaturedContents from "@/components/Features/FeaturedContents";

  export default {
    metaInfo: {
      title: "Featured Content",
      description:
        "Dystopian thriller author John Keeble's website contains an exclusive exposition of his facts-as-stories approach, an extended biography, a sneak preview of a work in progress, articles, and special offers when available. These are in a members' area but you can sign in to enter",
    },
    components: { FeaturedContents },
    data: () => ({
      disclaimer: require("@/assets/mailing-list-disclaimer.json"),
      email: "",
      remember: false,
    }),
    computed: {
      ...mapState(["features"]),
    },
    methods: {
      ...mapActions(["getFeatures"]),
    },
    mounted() {
      this.getFeatures();
    },
  };
</script>
