<template>
  <v-container>
    <v-img
      src="@/assets/images/bio-pic.jpg"
      contain
      max-width="45vw"
      max-height="60vh"
      class="float-right"
      :class="$vuetify.breakpoint.smAndDown ? 'ml-3' : ''"
    ></v-img>

    <div v-html="content"></div>
  </v-container>
</template>

<script>
// import { markdown } from "markdown";
import { mapState } from "vuex";
const md = require("markdown-it")({ html: true });

export default {
  metaInfo: {
    title: "About",
    description:
      "A short biography of dystopian thriller book author and journalist John Keeble. A 7,000-word biography, The Nine Lives of John Keeble, is available in the members' area of this website",
  },
  computed: {
    ...mapState(["siteData"]),
    content() {
      return md.render(this.siteData.about);
    },
  },
  mounted() {
    this.$store.dispatch("siteData", { dataName: "about", type: "md" });
  },
};
</script>
