export function getBase64(file, callback) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(reader.result.split(",")[1]);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}
