<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-navigation-drawer absolute permanent height="100vh">
          <v-simple-table>
            <tbody>
              <tr style="background-color: black">
                <td class="text-h6">
                  Sections
                  <v-btn icon class="ml-12" @click="showAddDialog = true"
                    ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
                  >
                </td>
              </tr>
              <tr
                v-for="feature in features"
                :key="`admin-feature-${feature.id}`"
                @click="editingFeature = { ...feature }"
                :class="selectedFeature == feature.id ? 'selected' : ''"
                style="cursor: pointer"
              >
                <td>
                  <v-toolbar flat color="transparent">
                    {{ feature.title }}
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      x-small
                      @click.stop="
                        deleteFeatureSection({
                          ...feature,
                          $confirm,
                        })
                      "
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </v-toolbar>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-navigation-drawer>

        <edit-feature-section
          :feature="editingFeature"
          v-if="editingFeature"
        ></edit-feature-section>

        <add-section-dialog
          :visible="showAddDialog"
          @update-visible="(v) => (showAddDialog = v)"
        ></add-section-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AddSectionDialog from "@/components/Admin/AddSectionDialog";
import EditFeatureSection from "@/components/Admin/EditFeatureSection";
import { mapState, mapActions } from "vuex";
export default {
  components: { EditFeatureSection, AddSectionDialog },
  data: () => ({
    editingFeature: null,
    showAddDialog: false,
  }),
  computed: {
    ...mapState(["features"]),
    selectedFeature() {
      if (!this.editingFeature) return 0;
      return this.editingFeature.id || this.features[0];
    },
  },
  methods: {
    ...mapActions(["deleteFeatureSection"]),
  },
  mounted() {
    this.$store.dispatch("getFeatures").then(() => {
      this.editingFeature = this.features[0];
    });
  },
};
</script>

<style>
.selected {
  background-color: #555555;
}
</style>
