<template>
  <div>
    <v-list>
      <v-list-group
        v-for="item in features"
        :key="item.name"
        v-model="item.active"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in formatChildren(item.items)"
          :key="child.title"
        >
          <v-list-item-content v-if="child.type == 'pdf'">
            <v-list-item-title>
              <a :href="`/api/get-feature/${child.id}`" class="link">
                <v-btn icon>
                  <v-icon> mdi-link-variant </v-icon>
                </v-btn>
                {{ child.name }}
              </a>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-content
            v-if="child.type == 'md'"
            class="md-format"
            v-html="formatHTML(child.contents)"
          >
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { startCase } from "lodash";
import markdown from "markdown-it";
const md = new markdown({ html: true, linkify: true, breaks: true });
md;

export default {
  computed: {
    ...mapState(["features"]),
  },
  methods: {
    formatName(name) {
      return startCase(name);
    },
    formatChildren(children) {
      children = children
        .filter(({ type }) => type == "pdf" || type == "md")
        .map(({ name, id, type, contents }) => ({
          name,
          id,
          type,
          contents,
        }));

      return children;
    },
    formatHTML(mdContent) {
      return md.render(mdContent);
    },
  },
  updated() {
    const timedElements = document.querySelectorAll("timed");
    timedElements.forEach((element) => {
      const attr = element.getAttributeNames().reduce((acc, name) => {
        return { ...acc, [name]: element.getAttribute(name) };
      }, {});
      const curDate = new Date().getTime();
      let show = true;
      if (attr.before) {
        if (curDate >= new Date(attr.before).getTime()) show = false;
      }
      if (attr.after) {
        if (curDate <= new Date(attr.after).getTime()) show = false;
      }
      if (attr.from && attr.to) {
        const from = new Date(attr.from).getTime();
        const to = new Date(attr.to).getTime();
        if (curDate < from || curDate > to) show = false;
      }
      if (!show) {
        element.classList.add("hidden");
      }
    });
  },
};
</script>

<style>
.link {
  text-decoration: none;
}

.md-format p {
  margin: 0.75rem 0 0.75rem 0;
  line-height: 1.3rem;
  max-width: 750px;
}

.hidden {
  display: none;
}
</style>
