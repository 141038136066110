<template>
  <v-col cols="8">
    <v-card>
      <v-card-title> Edit Feature Section </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-text-field
              label="Section Name"
              v-model="feature.title"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              type="number"
              label="Order"
              v-model="feature.order"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="pt-5">
            <v-btn icon @click="updateFeature(feature)">
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1">
            <v-btn icon @click="newItem(feature)">
              <v-icon> mdi-plus-circle-outline </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" class="pt-5"> New Section Item </v-col>

          <v-col cols="6" align="right" class="pt-5">
            Update Section Items
          </v-col>
          <v-col cols="2">
            <v-btn icon @click="updateFeatureItems({ feature, files })">
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-for="item in feature.items"
          :key="`admin-feature-item-${item.id}`"
          dense
        >
          <v-col :cols="item.type != 'md' ? 5 : 8">
            <v-text-field label="Name" v-model="item.name"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              label="Type"
              v-model="item.type"
              :items="['md', 'pdf']"
            ></v-select>
          </v-col>
          <v-col cols="4" v-if="item.type != 'md'">
            <v-file-input
              label="Replace File"
              v-model="itemFiles[item.id]"
              accept="application/pdf"
            ></v-file-input>
          </v-col>
          <v-col cols="1" class="mt-4 d-flex">
            <v-btn icon v-if="item.type == 'md'" @click="editMd(item.id)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="
                deleteSelected({
                  ...item,
                  $confirm,
                })
              "
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" v-if="item.type == 'md' && item.id == editingMd">
            <v-col cols="12">
              <v-textarea v-model="item.contents"> </v-textarea>
            </v-col>
            <v-col cols="12" align="right">
              <v-btn text @click="updateMd">Update</v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <feature-item-dialog :dialog="dialog"></feature-item-dialog>
  </v-col>
</template>

<script>
import FeatureItemDialog from "./FeatureItemDialog.vue";

import { mapActions } from "vuex";
export default {
  components: { FeatureItemDialog },
  props: ["feature"],
  data: () => ({
    dialog: { visible: false },
    editingMd: false,
    itemFiles: {},
    files: {},
  }),
  watch: {
    itemFiles() {
      const $this = this;
      for (let itemID of Object.keys(this.itemFiles)) {
        let itemFile = this.itemFiles[itemID];
        let fr = new FileReader();
        fr.addEventListener("load", function (e) {
          $this.updateFiles({
            id: itemID,
            name: itemFile.name,
            type: itemFile.type,
            contents: e.target.result,
          });
        });
        fr.readAsDataURL(this.itemFiles[itemID]);
      }
    },
  },
  methods: {
    ...mapActions(["deleteFeatureItem", "updateFeature", "updateFeatureItems"]),
    updateFiles(file) {
      file.contents = file.contents.replace("data:application/pdf;base64,", "");
      this.files[file.id] = file;
    },
    deleteSelected(item) {
      item;

      this.deleteFeatureItem(item).then((response) => {
        if (response)
          this.feature.items = this.feature.items.filter(
            ({ id }) => id != item.id
          );
      });
    },
    newItem(feature) {
      this.dialog = {
        visible: true,
        order: feature.items.length * 10 + 10,
        featureID: feature.id,
      };
    },
    updateMd() {
      const item = this.feature.items
        .filter(({ id }) => id == this.editingMd)
        .shift();
      console.log({ updateMd: item });
    },
    editMd(id) {
      if (this.editingMd != id) {
        this.editingMd = id;
        return;
      }

      this.editingMd = false;
    },
  },
};
</script>
