import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import wretch from "wretch";
import handleResponse from "@/assets/handle-response";
import { compress } from "@/assets/gz";
import { getBase64 } from "@/assets/file-base64";
import { kebabCase } from "lodash";

export default new Vuex.Store({
  state: {
    api: wretch("/api"),
    features: [],
    loggedIn:
      localStorage.getItem("features-login") ||
      sessionStorage.getItem("features-login") ||
      false,
    siteData: {
      bookData: [],
      about: "",
    },
    snack: {
      visible: false,
      color: "",
      text: "",
    },
    adminToken: sessionStorage.getItem("admin-token") || false,
    tokenValid: false,
  },
  mutations: {},
  actions: {
    adminSignIn({ state }, login) {
      state, login;
      return wretch("/api/admin-sign-in")
        .post(login)
        .json()
        .then(handleResponse)
        .then(({ token }) => {
          sessionStorage.setItem("admin-token", token);
          state.adminToken = token;
          state.tokenValid = true;
        })
        .catch((err) => {
          state.snack = {
            visible: true,
            color: "error",
            text: err,
          };
        });
    },
    async checkAdmin({ state }) {
      state;
      // check with token
      return await wretch(`/api/check-admin/${state.adminToken}`)
        .get()
        .json()
        .then(handleResponse)
        .then(() => {
          state.tokenValid = true;
        })
        .catch(() => {
          state.tokenValid = false;
        });
    },
    createFeatureItem({ state, dispatch }, item) {
      state, dispatch;
      console.log({ createFeatureItem: item });
      getBase64(item.contents, (contents) => {
        const postData = {
          featureID: item.featureID,
          name: item.name,
          type: item.type,
          order: item.order,
          filename: `${kebabCase(item.name)}.${item.type}`,
          contents,
        };
        wretch("/api/create-feature-item")
          .post(postData)
          .res()
          .then(() => {
            item.visible = false;
            dispatch("getFeatures");
          });
      });
    },
    createFeatureSection({ state, dispatch }, name) {
      if (name == "") {
        state.snack = {
          visible: true,
          color: "error",
          text: "Name is required to create a new section.",
        };
        return false;
      }

      return wretch("/api/create-feature-section")
        .post({ name })
        .res()
        .then(() => {
          dispatch("getFeatures");
          return true;
        });
    },
    deleteFeatureItem({ state }, item) {
      state;
      item = { ...item, table: "keeble_site_feature_contents" };
      return item
        .$confirm("Are you sure you want to delete this item?")
        .then((response) => {
          if (response) {
            return wretch("/api/delete-item")
              .post(item)
              .res()
              .then(() => {
                return true;
              })
              .catch(console.error);
          }

          return false;
        });
    },
    deleteFeatureSection({ dispatch }, item) {
      item = { ...item, table: "keeble_site_features" };
      item
        .$confirm("Are you sure you want to delete this item?")
        .then((response) => {
          if (response) {
            return wretch("/api/delete-item")
              .post(item)
              .res()
              .then(() => {
                dispatch("getFeatures");
              })
              .catch(console.error);
          }

          return false;
        });
    },
    getFeatures({ state }) {
      return wretch("/api/get-features")
        .get()
        .json()
        .then(handleResponse)
        .then(({ data }) => {
          state.features = data;
        })
        .catch(console.error);
    },
    signIn({ state, dispatch }, { email, remember }) {
      if (
        !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        state.snack = {
          visible: true,
          color: "error",
          text: "Invalid e-mail address",
        };

        return false;
      }

      wretch("/api/join-list")
        .formData({ email })
        .post()
        .json()
        .then(handleResponse)
        .then((response) => {
          remember;
          if (response) {
            dispatch("getFeatures");
            state.loggedIn = true;
            sessionStorage.setItem("features-login", email);
            if (remember) localStorage.setItem("features-login", email);

            state.snack = {
              visible: true,
              color: "success",
              text: "Thank you. You can now view the Features section.",
            };
          }
        })
        .catch((err) => {
          state.snack = {
            visible: true,
            color: "error",
            text: err,
          };
        });
    },
    signOut({ state }) {
      state.loggedIn = false;
      localStorage.removeItem("features-login");
      sessionStorage.removeItem("features-login");
    },
    siteData({ state }, { dataName, type }) {
      wretch(`/api/get-site-data/${dataName}/${type}`)
        .get()
        .json()
        .then(handleResponse)
        .then((data) => {
          state.siteData[dataName] = data;
        })
        .catch(console.error);
    },
    updateFeature({ dispatch }, feature) {
      wretch("/api/update-feature")
        .post(feature)
        .res()
        .then(() => {
          dispatch("getFeatures");
        })
        .catch(console.error);
    },
    async updateFeatureItems({ state }, { feature, files }) {
      wretch("/api/update-feature-items")
        .post({
          data: compress(JSON.stringify({ feature, files })),
        })
        .res()
        .then(() => {
          state.snack = {
            visible: true,
            color: "success",
            text: "Update successful",
          };
        })
        .catch((text) => (state.snack = { visible: true, color: "err", text }));
    },
  },
  modules: {},
});
