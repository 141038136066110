<template>
  <div>
    <page-layout>
      <home-carousel :show-blurb="true" @set-item="setItem"></home-carousel>

      <div v-html="item.notes"></div>

      <h1>Reviews:</h1>

      <template v-if="item.reviews && item.reviews.length > 0">
        <v-card
          v-for="(review, index) in item.reviews"
          :key="`review-${item.title}-${index}`"
          class="mb-2"
        >
          <v-card-title>
            {{ review.subject }}
          </v-card-title>
          <v-card-text v-html="review.text"> </v-card-text>
        </v-card>
      </template>

      <template v-else> This title has not been reviewed yet. </template>
    </page-layout>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  import PageLayout from "@/components/PageLayout.vue";
  import HomeCarousel from "@/components/HomeCarousel.vue";

  export default {
    metaInfo: {
      title: "Home",
      description:
        "Dystopian thrillers by book author and journalist John Keeble, reviews and retail links, plus biography and a members' area with a work in progress, exposition of dystopian book themes, an extended biography, articles, and special offers when available",
    },
    components: { PageLayout, HomeCarousel },
    data: () => ({
      item: {},
      email: "",
    }),
    methods: {
      ...mapActions(["joinList"]),
      async join() {
        const response = await this.joinList(this.email);
        if (response) this.email = "";
      },
      setItem(item) {
        if (!item) return {};
        this.item = item;
      },
    },
  };
</script>
