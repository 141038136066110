import router from "../router";
import uniqid from "uniqid";

export default router.options.routes
  .filter(({ meta }) => meta.show)
  .map((route) => {
    return {
      id: uniqid(),
      name: route.name,
      path: route.path,
    };
  });
