<template>
  <v-dialog v-model="dialog.visible" max-width="750px">
    <v-card>
      <v-card-title> New Feature Item </v-card-title>
      <v-form ref="featureItemForm">
        <v-card-text>
          <v-text-field label="Name" v-model="dialog.name"></v-text-field>
          <v-select
            :items="types"
            label="Type"
            v-model="dialog.type"
          ></v-select>
          <v-text-field
            type="number"
            label="Order"
            v-model="order"
          ></v-text-field>
          <v-file-input
            v-model="dialog.contents"
            label="Contents"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createFeatureItem(dialog)">Continue</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["dialog"],
  data: () => ({
    item: { name: null, type: null, order: null },
    types: ["pdf", "md"],
  }),
  computed: {
    order: {
      get() {
        return this.item.order || this.dialog.order || 0;
      },
      set(v) {
        this.item.order = v;
      },
    },
  },
  methods: {
    ...mapActions(["createFeatureItem"]),
  },
};
</script>
