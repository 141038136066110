<template>
  <div>
    <v-list dense>
      <template v-for="item in navItems">
        <v-list-item
          :key="item.id"
          :to="item.path"
          v-if="item.type != 'spacer' && !item.children"
          @click="$emit('close')"
        >
          <v-list-item-title>
            {{ item.name.toUpperCase() }}
          </v-list-item-title>
        </v-list-item>
        <v-divider v-if="item.type == 'spacer'" :key="item.id"></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import navItems from "@/assets/nav-items";
export default {
  data: () => ({
    navItems,
  }),
};
</script>
