<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-card height="385">
          <v-card-title> Contact John Keeble </v-card-title>
          <v-card-text>
            You can contact me at this email address:
            <v-img src="@/assets/images/email.jpg" width="288"></v-img>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-img
          src="@/assets/images/contact-pic.jpg"
          height="385px"
          position="top"
          class="mb-2"
        >
          <v-footer absolute>
            <div style="width: 100%;" class="text-center">
              No matter where I am, I want to hear from you.
            </div>
          </v-footer>
        </v-img>
      </v-col>
    </v-row>
    <mailing-list-info-dialog :info="info"></mailing-list-info-dialog>
  </v-container>
</template>

<script>
  import MailingListInfoDialog from "@/components/MailingListInfoDialog";
  export default {
    metaInfo: {
      title: "Contact",
      description:
        "Contact dystopian thriller author and journalist John Keeble. Wherever he is, he will be pleased to hear from you",
    },
    components: { MailingListInfoDialog },
    data: () => ({
      name: "",
      email: "",
      subject: "",
      message: "",
      joinMailingList: false,
      info: {
        visible: false,
      },
    }),
  };
</script>
